import { controlNames } from "../../constants";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import FormValidator from "../../services/validationService";
import AppStore from "../../store/AppStore";
import VehicleLookupHelper, {
  isVehicleModelListParamsOkShort,
} from "../vehicleLookupHelper";

export const getFilteredModelList = (modelList) => {
  const engineCcAsNumber = parseInt(AppStore.getControlByName(controlNames.engineCC));
  const licenceTypeMain = AppStore.getControlByName(controlNames.licenceType);

  //Shows warning if user picked moped licence and selected model is > 50cc
  const isModelUnderEngineCC = (modelDescription, enginecc) => {
    let match = modelDescription.match(/\,\ ([0-9]+)CC\,/);
    if (!isNullOrUndefined(match) && match.length === 2) {
      let bikeEngineCC = parseInt(match[1]);
      if (bikeEngineCC > enginecc) return false;
    }
    return true;
  };

  const isModelElectric = (modelDescription) => {
    return (
      modelDescription === "Choose your bike model" ||
      modelDescription?.includes("Electric")
    );
  };

  const filteredVehicleList =
    licenceTypeMain === "M"
      ? engineCcAsNumber === 50 || engineCcAsNumber === 49
        ? modelList.filter((item) => {
            return isModelUnderEngineCC(item.displayedModel, engineCcAsNumber);
          })
        : engineCcAsNumber < 49
        ? modelList.filter((item) => {
            return isModelElectric(item.displayedModel);
          })
        : []
      : [...modelList];

  return filteredVehicleList;
};

function handleModelOnChange(controlName, controlValue) {
  // The user has selected a value from the 'bike-model' dropdown.
  // We put all their entered bike details into a card.
  // Visibility settings for the following controls are set to false.
  if (controlValue === true) {
    const regValue = AppStore.getControlByName(controlNames.bikeRegNo);

    if (isNullOrUndefined(regValue) || regValue === "")
      AppStore.setFormData({ [controlNames.bikeRegNo]: "" });

    AppStore.setVisibilityControls({
      [controlNames.bikeManufacturer]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.engineCC]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.yearManufacture]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.bikeModel]: false,
    });
  } else {
    AppStore.addShouldRevalidate(controlNames.engineCC);

    AppStore.setHasChanged(controlNames.bikeModel, false);
  }
}

function handleValidationForModelVisibility(controlName, controlValue) {
  const usesRegLookupCard =
    AppStore.getUserJourneySettings()?.customControls?.usesRegLookupCard || false;

  // if we are using the dataListItem card to display vehicle information in, we first want to check that all fields are valid,
  // before attempting to show the bike-model control. If there is an invalid field, this won't be realised until the user tries to
  // submit the form (as the fields / errors will be invisible)
  let allFieldsValid = true;

  if (usesRegLookupCard) {
    allFieldsValid = areVehicleFieldsValid();
  }

  // only show the bike-model control if all three of the previous controls are valid (if usesRegLookupCard).
  if (allFieldsValid === true) {
    AppStore.setHasChanged(controlNames.bikeModel, false);
    if (usesRegLookupCard) {
      AppStore.setVisibilityControls({
        [controlNames.bikeModel]: true,
      });
    }
  } else {
    if (usesRegLookupCard) {
      AppStore.setVisibilityControls({
        [controlNames.bikeModel]: false,
      });
    }
  }
}

const areVehicleFieldsValid = () => {
  const manufacturerValue = AppStore.getControlByName(controlNames.bikeManufacturer);
  const engineCCValue = AppStore.getControlByName(controlNames.engineCC);
  const yearManufactureValue = AppStore.getControlByName(controlNames.yearManufacture);

  // we set up keyValues to set up the values and names of the relevant vehicle detail controls
  // so that we can easily validate them.
  const keyValues = [
    { name: controlNames.bikeManufacturer, value: manufacturerValue },
    { name: controlNames.engineCC, value: engineCCValue },
    { name: controlNames.yearManufacture, value: yearManufactureValue },
  ];
  const validator = new FormValidator();

  // validate the vehicle details fields. If any fields are invalid, assign allFieldsValid to be false;
  for (let item of keyValues) {
    const result = validator.validateField({
      [item.name]: item.value,
    });
    if (result.isValid === false) return false;
  }

  return true;
};

function handleLookupSubControls(controlName, controlValue) {
  const isModelPageLoad =
    controlName === controlNames.bikeModel && controlValue === "page-load";
  const isNotModel = controlName !== controlNames.bikeModel;

  let manufactureValue = AppStore.getControlByName(controlNames.bikeManufacturer);
  let engineCCValue = AppStore.getControlByName(controlNames.engineCC);
  let yearOfManufactureValue = AppStore.getControlByName(controlNames.yearManufacture);
  let modelValue = AppStore.getControlByName(controlNames.bikeModel);

  if (controlName !== controlNames.bikeManufacturer) {
    manufactureValue = AppStore.getControlByName(controlNames.bikeManufacturer);
  }

  if (controlName !== controlNames.engineCC) {
    engineCCValue = AppStore.getControlByName(controlNames.engineCC);
  }

  if (controlName === controlNames.yearManufacture) {
    yearOfManufactureValue = controlValue;
    //populate purchase date year list according to year manufacture value
    if (!AppStore.getControlByName("vehicle-not-purchased")) {
      AppStore.setFormData({
        "purchase-date": "invalid-date" + controlValue,
      });
    }
    AppStore.setUIState({ key: controlName, value: controlValue });
  } else {
    yearOfManufactureValue = AppStore.getControlByName(controlNames.yearManufacture);
  }

  if (controlName === controlNames.bikeModel && !isModelPageLoad) {
    modelValue = ev?.target?.value;
    const type = AppStore.getVehicleModelsList().find(
      (item) => item.key === modelValue
    )?.type;
    AppStore.setFormData({
      "bike-type": type,
    });
  } else {
    modelValue = AppStore.getControlByName(controlNames.bikeModel);
  }

  if (isModelPageLoad || isNotModel) {
    // if subcontrols change, reset the model value
    if (!isModelPageLoad) {
      AppStore.setFormData({ [controlNames.bikeModel]: "" });
    }
    AppStore.setHasChanged(controlNames.bikeModel, false); // only if model value is empty on page load

    if (
      !isVehicleModelListParamsOkShort(
        engineCCValue,
        manufactureValue,
        yearOfManufactureValue
      )
    )
      return;

    VehicleLookupHelper.GetVehicleModelList(
      manufactureValue,
      engineCCValue,
      yearOfManufactureValue
    ).then((modelListResponse) => {
      if (
        !isNullOrUndefined(modelListResponse) &&
        !isNullOrUndefined(modelListResponse.data)
      ) {
        if (modelListResponse.data.isError || modelListResponse.data.length === 0) {
          AppStore.setFormData({
            [controlNames.bikeModel]: "",
          });
          AppStore.setVehicleModelsList([]);
        } else {
          let filteredModelList = getFilteredModelList(modelListResponse.data);

          if (filteredModelList.length !== 0) {
            filteredModelList = [
              {
                key: "",
                description: "Choose your bike model",
                type: "",
              },
            ].concat(
              filteredModelList.map((item) => {
                return {
                  key: item.abiCode,
                  description: item.displayedModel,
                  type: item.type,
                };
              })
            );
          }
          // reset model control valuesList and value so observable will notice a change and rerender
          // saving again vehicleDetails list and model value so model control observable will notice a change
          AppStore.setVehicleModelsList(filteredModelList);
        }
      }
    });
  }
}

function handleRegistrationSearch(controlName, controlValue) {
  if (controlValue === "manual-details") {
    const manualLookupControls = [
      controlNames.bikeManufacturer,
      controlNames.engineCC,
      controlNames.yearManufacture,
    ];

    // The user has clicked the 'Enter details manually' button
    // We wipe the manual lookup fields of their data.
    AppStore.deleteFormDataControls(manualLookupControls);

    // setHasChanges toggles the display of is valid tick icon
    // This state change needs to happen before setting visibility to true
    // ⚠ This should trigger a rerender so it is odd it's not geeting to the browser correctly (to be investigated)

    const allVisibleSearchControls = [...manualLookupControls, controlNames.bikeModel];

    allVisibleSearchControls.forEach((control) => {
      AppStore.setHasChanged(control, false);
    });

    // Visibility settings for the following controls are set to true.
    // We then check to see if the 'bike-model' control should also be displayed
    manualLookupControls.forEach((control) => {
      AppStore.setVisibilityControls({
        [control]: true,
      });
    });
  }
  // TODO Check if there is a more intuitive way to do this check
  else if (Array.isArray(controlValue)) {
    // partial reg lookup (or other cases not discovered it)
    if (AppStore.liveValidation) {
      // TODO Clarify what this part does
      AppStore.setVisibilityControls({
        [controlNames.bikeModel]: false,
      });

      for (let item of controlValue) {
        AppStore.addShouldRevalidate(item[0]);
      }

      AppStore.setHasChanged(controlNames.bikeManufacturer, true);
      AppStore.setHasChanged(controlNames.engineCC, true);
      AppStore.setHasChanged(controlNames.yearManufacture, true);
      AppStore.setHasChanged(controlNames.bikeModel, false);
    }
    AppStore.setVisibilityControls({
      [controlNames.bikeManufacturer]: true,
    });
    AppStore.setVisibilityControls({
      [controlNames.engineCC]: true,
    });
    AppStore.setVisibilityControls({
      [controlNames.yearManufacture]: true,
    });

    if (controlValue.length < 1) {
      // Display bike-model dropdown when lookup reg doesn't return an exact model
      AppStore.setVisibilityControls({
        [controlNames.bikeModel]: true,
      });
    }
  } else if (controlValue === "not-my-bike") {
    const controlsToReset = [
      controlNames.bikeRegNo,
      controlNames.bikeManufacturer,
      controlNames.engineCC,
      controlNames.yearManufacture,
      controlNames.bikeModel,
    ];

    controlsToReset.forEach((control) => {
      AppStore.deleteFormData(control);
      AppStore.setHasChanged(control, false);
    });

    AppStore.setVisibilityControls({
      [controlNames.bikeManufacturer]: true,
    });
    AppStore.setVisibilityControls({
      [controlNames.engineCC]: true,
    });
    AppStore.setVisibilityControls({
      [controlNames.yearManufacture]: true,
    });
    AppStore.setVisibilityControls({
      [controlNames.bikeModel]: false,
    });
  } else if (controlValue === "not-my-car") {
    const controlsToReset = [
      controlNames.carRegistrationSearch,
      controlNames.carManufacturerControlName,
      controlNames.engineCC,
      controlNames.yearManufacture,
      controlNames.carModelControlName,
      controlNames.fuelTypeControlName,
      controlNames.transmissionControlName,
    ];

    controlsToReset.forEach((control) => {
      AppStore.deleteFormData(control);
      AppStore.setHasChanged(control, false);
    });
  } else {
    // The user has created a card from their entered bike details.
    // Visibility settings for the following controls are set to false.
    // We then check to see if the 'bike-model' control should also be displayed
    AppStore.setVisibilityControls({
      [controlNames.bikeManufacturer]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.engineCC]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.yearManufacture]: false,
    });
    AppStore.setVisibilityControls({
      [controlNames.bikeModel]: false,
    });
  }
}

function manipulateControls(controlName, controlValue) {
  const isNqs =
    AppStore.getUserJourneySettings()?.generalSettings?.vehicleLookupVersion === "nqs";

  if (
    isNqs &&
    ["bike-manufacturer", "engine-cc", "year-manufacture"].includes(controlName)
  ) {
    handleValidationForModelVisibility(controlName, controlValue);
  }

  // handle "page-load" regarless of nqs
  // this makes sure vehicle model list api request gets sent on page load
  if (
    (controlName === "bike-model" && controlValue === "page-load") ||
    (isNqs &&
      ["bike-manufacturer", "engine-cc", "year-manufacture"].includes(controlName))
  ) {
    handleLookupSubControls(controlName, controlValue);
  }

  if (
    isNqs &&
    ["bike-registration-search", controlNames.carRegistrationSearch].includes(controlName)
  ) {
    handleRegistrationSearch(controlName, controlValue);
  }
}

export function manipulateControlsOnChange(controlName, controlValue) {
  if (
    AppStore.getUserJourneySettings()?.generalSettings?.vehicleLookupVersion === "nqs" &&
    controlName === controlNames.bikeModel
  ) {
    handleModelOnChange(controlName, controlValue);
  }
}

function manipulateControlsPreRender(controlName) {}

export default {
  manipulateControls,
  manipulateControlsOnChange,
  manipulateControlsPreRender,
  areVehicleFieldsValid,
};
