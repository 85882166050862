import dayjs from "dayjs";
import { sha256 } from "js-sha256";
import { cookies } from "../helpers/cookies";
import dateParser from "../helpers/dateParser";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

const Permutive = {
  init(settings) {
    try {
      if (settings.enabled === "true") {
        const head = document.getElementsByTagName("head")[0];
        const toInsert = document
          .createRange()
          .createContextualFragment(
            this.getPermutiveTag(settings.apiKey, settings.workspaceID)
          );
        head.appendChild(toInsert);
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive init");
    }
  },
  handleNewPage() {
    try {
      if (window.permutive) {
        let cookieConsent = { "opt-in": false };
        if (cookies.get("CookieConsent") === "true") {
          cookieConsent = { opt_in: true, token: "true" };
        }
        window.permutive.consent(cookieConsent);
        this.updateData();

        this.sendUserID();
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive newpage");
    }
  },
  getPermutiveTag(apiKey, workspaceID) {
    const tag = `<script>
            !function(n,e,i){if(!n){n = n || {}, window.permutive = n, n.q = [], n.config = i || {}, n.config.apiKey = e, n.config.environment = n.config.environment || "production";for(var o=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<o.length; r++){var t=o[r];n[t]=function(e){return function(){var i=Array.prototype.slice.call(arguments,0);n.q.push({functionName:e,arguments:i})}}(t)}}}(window.permutive, "${apiKey}", { "consentRequired": true });
            window.googletag=window.googletag||{ },window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var g=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",g?JSON.parse(g):[])}});
        </script>
        <script async src="https://cdn.permutive.app/${workspaceID}-web.js"></script>`;

    return tag;
  },
  updateData() {
    try {
      let data = {
        page: {},
      };

      const schema = AppStore.getUserJourneySettings().tracking.permutive.schema;
      Object.keys(schema).forEach((field) => {
        data.page[field] = this.formatFormData(schema[field]);
      });
      window.permutive.addon("web", data);
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive updateData");
    }
  },

  formatFormData(schema) {
    let data = {};
    Object.keys(schema).forEach((permutiveKey) => {
      const controlName = schema[permutiveKey];
      let value = AppStore.getControlByName(controlName);

      switch (permutiveKey) {
        case "dob":
          if (!isNullOrUndefined(value) && value != "")
            value = dayjs(value).format("YYYY-MM-DDThh:mm:ss[Z]");
          break;
        case "age":
          if (!isNullOrUndefined(value) && value != "")
            value = dateParser.getDateDiffInYears(value, dayjs());
          break;
        case "occupations":
          let occupationsList = [];
          schema["occupations"].forEach((control) => {
            const val = AppStore.getControlByName(control);
            const desc = AppStore.getDescriptionByCode(val, control);
            if (!isNullOrUndefined(desc) && desc !== "") occupationsList.push(desc);
          });
          value = occupationsList;
          break;
        case "occupation_industry":
          value = AppStore.getDescriptionByCode(value, controlName);
          break;
        case "bike-model-description":
          value = value.split(",")[0];
          break;
        case "value":
        case "year_of_manufacture":
          value = parseInt(value);
          break;
        case "insurance_renewal_date":
        case "licence_date_obtained":
        case "purchase_date":
          if (!isNullOrUndefined(value) && value != "")
            value = dayjs(value).format("YYYY-MM-DDT00:00:00[Z]");
          break;
        case "not_purchased_yet":
          if (!isNullOrUndefined(value)) value = Boolean(value);
          break;
        case "licence_not_yet_obtained":
          if (!isNullOrUndefined(value)) value = Boolean(value);
          break;
        case "home_owner":
        case "car_owner":
          value = value === "yes";
          break;
        case "security_devices":
          if (value === "yes") value = "true";
          else value = "false";
        case "engine":
          value = parseInt(value);
          break;
      }

      if (!isNullOrUndefined(value) && value != "") data[permutiveKey] = value;
    });

    return data;
  },

  sendUserID() {
    try {
      const sendUserIdEnabled =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.enabled ===
          "true";

      const saltKey =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
            .saltKey
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .saltKey !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .saltKey
          : false;
      const saltTag =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .tag !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .tag
          : "bauer_id";
      const saltPriority =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .priority !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .priority
          : "0";

      const emailTag =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
          .tag !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
              .tag
          : "email_sha256";
      const emailPriority =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
          .priority !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
              .priority
          : "1";

      if (sendUserIdEnabled && saltKey) {
        let user_email =
          !isNullOrUndefined(AppStore.getControlByName("email")) &&
          AppStore.getControlByName("email") != ""
            ? AppStore.getControlByName("email").trim()
            : false;

        if (user_email) {
          let hashed_email = sha256(user_email);
          let salted_hashed_email = sha256(user_email + saltKey);

          window.permutive.identify([
            {
              id: salted_hashed_email,
              tag: saltTag,
              priority: saltPriority,
            },
            {
              id: hashed_email,
              tag: emailTag,
              priority: emailPriority,
            },
          ]);
        }
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive userID");
    }
  },
};

export default Permutive;
